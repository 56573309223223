import React, { Component } from 'react';
import Header from '../../components/Header/Header'

export default class EditSRL extends Component {
  render() {
    return <div>
        <Header ft={false}/>
    </div>;
  }
}

import React, { Component } from 'react';
import './Footer.css'
export default class Footer extends Component {
  render() {
    return (
    <div className='footer-buttom'>
        <div>   
        @copyright HKU-EDU QuicBoar 版权所有
        </div>
    </div>
    )
  }
}

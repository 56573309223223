import './App.css';
import RouteWrap from './Route'

function App() {
  return (
    <div className='App'>
        <RouteWrap/>
    </div>
  );
}

export default App;
